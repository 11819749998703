<template>
  <div id="app" style="width: 100%; margin: 0; padding: 0"
    :class="{ needScale: !['/accountform', '/appform', '/studentrequest'].includes($route.path) }">
    <header v-if="canShowHeader" class="head">
      <img :src="require('./assets/header_logo.png')" class="logo" />
      <div>
        <span style="margin-right: 15px">{{
          this.$UserData.user_name +
          "&nbsp [ " +
          this.$UserData.account_country +
          " ]"
        }}</span>
        <i class="el-icon-switch-button" @click="logout()" style="margin-right: 24px; cursor: pointer"></i>
      </div>
    </header>
    <router-view></router-view>
  </div>
</template>
<script>
import {
  getUserName,
  removeUserId,
  removeUserName,
  getUserAccountCountry,
  removeUserAccountCountry,
} from "./utils/store";
import './assets/common/common.css'
import './assets/common/font.css'
export default {
  name: "app",
  data() {
    return {};
  },
  computed: {
    canShowHeader() {
      var show = false;
      if (
        this.$route.path.search("login") >= 0 ||
        this.$route.path.search("cert") >= 0 ||
        this.$route.path.search("transcript") >= 0 ||
        this.$route.path.search("register") >= 0
      ) {
        show = true;
      }

      return !show;
    },
  },
  mounted() {
    console.log('测试自动部署111')
    this.$UserData.user_name = getUserName();
    this.$UserData.account_country = getUserAccountCountry();
  },
  methods: {
    logout() {
      this.$confirm("Are you sure you want to log out?", "Log Out Confirmation", {
        confirmButtonText: 'Log Out',
        cancelButtonText: 'Cancel',
        type: "warning",
      })
        .then(() => {
          removeUserId();
          removeUserName();
          removeUserAccountCountry();
          this.$router.replace('/login')
        })
        .catch(() => { });
    },
  },
};
</script>
<style>
* {
  padding: 0;
  margin: 0;
}

body {
  font-family: GravityNormal;
}
</style>
<style lang="scss" scoped>
.needScale {
  width: 100vw;
}

.head {
  height: 72px;
  position: relative;
  font-size: 12px;
  background-color: var(--c-primary);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    width: 160px;
    height: auto;
    margin-left: 24px;
  }
}

/deep/.el-main {
  margin: 0px;
  padding: 0px;
  background: #f8f8f8;
}

.el-container {
  margin: 0px;
  padding: 0px;
  // min-width: 1920px;
}

#app {
  padding: 0;
  margin: 0;
  color: #606266;
}

.el-menu {
  background: none;
}
</style>
