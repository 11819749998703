import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./element-variables.scss";
import "normalize.css";
import router from "./router";
import UserData from "./utils/UserData";
import dayjs from "dayjs";
import pdf from "./utils/pdf";
Vue.use(pdf);

Vue.prototype.$dayjs = dayjs;
Vue.prototype.$UserData = UserData;
Vue.config.productionTip = false;
Vue.use(ElementUI);

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");