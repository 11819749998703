import Cookies from "js-cookie";

const EAE_USER_ID = "swpr-user_id";
const EAE_USER_NAME = "wspr-user_name";

const EAE_USER_ACCOUNT_COUNTRY = "wapr-user_account_country";



export function setUserAccountCountry(country) {
    Cookies.set(EAE_USER_ACCOUNT_COUNTRY, country);
}

export function getUserAccountCountry() {
    return Cookies.get(EAE_USER_ACCOUNT_COUNTRY);
}

export function removeUserAccountCountry() {
    Cookies.remove(EAE_USER_ACCOUNT_COUNTRY);
}


export function setUserId(user_id) {
    Cookies.set(EAE_USER_ID, user_id);
}

export function getUserId() {
    return Cookies.get(EAE_USER_ID);
}

export function removeUserId() {
    Cookies.remove(EAE_USER_ID);
}

export function setUserName(user_name) {
    Cookies.set(EAE_USER_NAME, user_name);
}

export function getUserName() {
    return Cookies.get(EAE_USER_NAME);
}

export function removeUserName() {
    Cookies.remove(EAE_USER_NAME);
}