import Vue from "vue";
import VueRouter from "vue-router";
import { getUserId } from "../utils/store";

Vue.use(VueRouter);

let routes;


routes = [{
        path: "/login",
        name: "Login",
        component: () =>
            import ("../views/login/index.vue"),
        meta: {
            requiresAuth: false,
            title: "登录",
        },
    },
    {
        path: "/cert",
        name: "index",
        component: () =>
            import ("../views/cert/index.vue"),
        meta: {
            requiresAuth: true,
            title: "证书",
        },
    },
    {
        path: "/transcript",
        name: "index",
        component: () =>
            import ("../views/transcript/index.vue"),
        meta: {
            requiresAuth: true,
            title: "Transcript",
        },
    },
    {
        path: "/register",
        name: "index",
        component: () =>
            import ("../views/register/index.vue"),
        meta: {
            requiresAuth: false,
            title: "注册",
        },
    },
    {
        path: "/",
        redirect: "/home",
        meta: {
            requiresAuth: true,
            title: "首页",
        },
    },

    {
        path: "/pay",
        name: "index",
        component: () =>
            import ("../views/stripePay/index.vue"),
        meta: {
            requiresAuth: true,
            title: "Pay",
        },
    },
    {
        path: "/home",
        redirect: '/home/applications',
        component: () =>
            import ("../views/Home"),
        meta: {
            requiresAuth: true,
            title: "首页",
        },
        children: [{
                path: "applications",
                name: "index",
                component: () =>
                    import ("../views/applications/index.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Applications",
                },
            },
            {
                path: "feedback",
                name: "index",
                component: () =>
                    import ("../views/feedback/index.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Feedback",
                },
            },
            {
                path: "accountInfo",
                name: "index",
                component: () =>
                    import ("../views/account/index.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Account Info",
                },
            },
            {
                path: "application",
                name: "index",
                component: () =>
                    import ("../views/pay/index.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Application",
                },
            },
            {
                path: "entry",
                name: "index",
                component: () =>
                    import ("../views/entry/index.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Entry",
                },
            }



        ]
    }
];

const router = new VueRouter({
    linkActiveClass: "active",
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.path === "/login") {
        next();
    } else {
        var userId = getUserId();
        console.log('to.meta.requiresAuth :' + to.meta.requiresAuth)
        console.log('userId :' + userId)
        if (to.meta.requiresAuth && (userId == undefined || userId == "")) {

            next({
                path: "/login",
            });
        } else {
            next();
        }
    }
});
export default router;